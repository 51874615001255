.blog-list {
    &__title {
        @include lib-heading-as-link(h3);
    }

    &__item {
        margin-bottom: 25px;
    }

    &__image {
        height: 0;
        margin-bottom: 15px;
        padding-bottom: 62%;
        background-position: center;
        background-size: cover;
    }
}

.blog-list-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

.related-posts {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin: 0 -30px;

    a {
        color: $text__color;

        &:hover,
        &:active,
        &:visited {
            color: $text__color;
        }
    }

    &__title {
        border-top: 1px solid $border-color__base;
        padding: 20px 0 40px;
        margin: 0;
    }

    &__post {
        width: calc(100% / 3);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 0 30px;

        p {
            margin-bottom: 30px;
        }

        img {
            margin-bottom: 20px;
        }
    }
}

@include min-screen($screen__m) {
    .blog-list {
        &__row {
            @include make-row();
            margin-bottom: -25px;
        }

        &__item {
            @include make-col-ready();
            @include make-col(8);
        }

        &__title-anchor {
            @include text-truncate(
                $font-size: 20px,
                $line-height: 1.4
            );
        }
    }
}
