li[class^="item m-navigation-show-more-"] {
  display:none;
}
span[class^="more-links-m-navigation-show-more-"] {
  cursor:pointer;
  color: $brand__primary__color;

  &:hover {
    text-decoration: underline;
  }
}
