.combideals-wrapper {
    margin-bottom: 40px;
}

.combideal {
    margin-bottom: 20px;
    border: 1px solid $theme-border-color;
    padding: 25px 15px 15px;

    &__images {
        flex: 1;
    }

    &__titles {
        @include lib-list-reset-styles();
        flex-wrap: wrap;
        margin: 0 0 25px;
        padding: 0 0 20px;
        border-bottom: 1px solid $theme-border-color;
    }

    &__title {
        @include lib-heading-as-link(h4);
        font-weight: $font-weight__medium;
        display: inline;
        margin: 0 0 5px;

        &:not(:last-of-type) {
            &::after {
                content: '+';
                margin: 0 7px 0 10px;
                color: $theme-icon-color-light;
                font-size: 19px;
                line-height: 1;
            }
        }
    }

    &__details {
        padding-right: 60px;

        > .price-final_price,
        .special-price,
        .normal-price,
        .minimal-price {
            .price {
                @extend .abs-price-lg;
            }
        }

        .price-box .old-price {
            .price {
                font-size: 15px;
                color: $color-gray60;
            }
        }
    }

    &__discount {
        @extend .abs-accept-text;
        margin-bottom: 10px;
    }

    &__images {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -15px;
    }

    &__image-wrapper {
        position: relative;
        padding-left: 15px;
        text-align: center;
        width: (100% / 3);
        box-sizing: border-box;

        &:not(:first-child) {
            @include lib-icon-font(
                $_icon-font-content: $icon-expand,
                $_icon-font-color: $theme-icon-color-light
            );
            font-size: 11px;
            margin-left: 40px;

            &::before {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: -17px;
                transform: translateY(-50%);
            }
        }
    }

    &__button {
        @include lib-icon-font(
            $_icon-font-content: $icon-cart,
            $_icon-font-margin: 0 5px 0 -3px,
            $_icon-font-size: 18px
        );
        padding: 9px 15px;
    }
}

@include min-screen($screen__l) {
    .combideal {
        &__content {
            display: flex;
            flex-wrap: wrap;
        }

        &__images {
            padding-right: 15px;
        }
    }
}

@include min-screen($screen__m) {
    .combideals-wrapper {
        margin-bottom: 60px;
    }
}

@include max-screen($screen__l) {
    .combideal {
        width: 45%;
    }
}

@include max-screen($screen__m) {
    .combideal {
        width: 82%;
    }
}
