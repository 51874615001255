.catalog-product-view {
    .product-info-price {
        display: flex;
        align-items: flex-start;
    }

    .special-price,
    .price-box {
        margin: 0;
    }

    .price-box {
        display: flex;
        flex-wrap: wrap;

    }
}

.traffic-light {
    align-self: flex-end;
    margin-bottom: 5px;

    &__text {
        position: relative;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 0.04em;
        font-weight: $font-weight__bold;

        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &--in-stock {
            @include lib-icon-font(
                $_icon-font-content: $icon-delivery,
                $_icon-font-size: 28px
            );

            color: $accept__color;
            padding-left: 35px;
        }

        &--out-of-stock {
            color: $text__color__muted;
        }
    }

    &.hide {
        display: none;
    }
}
