//
//  Variables
//  _____________________________________________

$active-nav-indent: 42px !default;

.panel.header {
    .topbar__list,
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    @include lib-css(background, $navigation__background);
    margin-bottom: 25px;
}

.nav-toggle {
    @include lib-icon-font(
        $_icon-font-content     : $icon-menu,
        $_icon-font-size        : 16px,
        $_icon-font-color       : $middle-bar__action__color,
        $_icon-font-color-hover : $middle-bar__action__color
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    z-index: $z-index__overlay;
}

.nav-toggle-mobile {
    @include lib-icon-font(
        $_icon-font-content     : $icon-remove,
        $_icon-font-size        : 16px,
        $_icon-font-color       : $theme-icon-color-regular
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    top: 10px;
    right: 15px;
}

@mixin off-canvas-menu-item(
    $item: a,
    $trigger: '[data-toggle=dropdown]',
    $active: '[data-toggle=dropdown].active',
    $dropdown: '[data-target=dropdown]'
) {
    @include lib-list-reset-styles();

    li {
        display: block;
        margin: 0;
    }

    #{$dropdown} {
        border-left: 2px solid $border-color__base;
        padding-left: 15px;
    }

    #{$trigger} + #{$dropdown} {
        display: none;
    }

    #{$trigger}[aria-expanded="true"] + #{$dropdown} {
        display: block;
    }

    .main__inner-link + #{$dropdown} {
        border-left: 0;
        padding-left: 0;
    }

    #{$item} {
        @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
        display: flex;
        font-size: 15px;
        font-weight: $font-weight__regular;
        padding: 5px 0;
    }

    [aria-hidden=true] {
        display: none;
    }

    #{$trigger} {
        @include lib-icon-font(
            $_icon-font-content  : $icon-down,
            $_icon-font-size     : 12px,
            $_icon-font-position : after,
            $_icon-font-display  : block
        );
        position: relative;

        &::after {
            position: absolute;
            right: 17px;
            top: 8px;
            pointer-events: none;
        }
    }

    #{$active} {
        @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-up,
            $_icon-font-position : after
        );
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100vw - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        padding-top: 30px;
        width: 80%;
        width: calc(100vw - #{$active-nav-indent});

        .section-item-content > ul {
            @include off-canvas-menu-item();

            li {
                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $color-gray82;
                    border-bottom: 1px solid $color-gray82;
                    font-weight: $font-weight__regular;
                    padding: $navigation-level0-item__padding;
                }
            }

            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .switcher-language {
        @include off-canvas-menu-item($item: 'a, strong');

        ul {
            @include lib-list-reset-styles();
        }

        .view-default {
            display: none;
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100vw - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: $z-index__off-canvas-menu;
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        @include lib-heading(h2);
        font-size: 17px;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 10px 15px;

        .nav-sections-item-switch {
            font-weight: $font-weight__semibold;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        box-sizing: border-box;
        padding: 0 0 20px 15px;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .header.panel > .header.links > .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        @include lib-css(background, $navigation-desktop__background);
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .topbar__list,
        .links,
        .switcher {
            display: inline-block;
        }
    }

    .nav-toggle-mobile {
        display: none;
    }

    .nav-toggle {
        display: none;
    }
}
