//
//  Product Lists
//  _____________________________________________

.product-item-info {
    position: relative;
}

.product-list-wrapper {
    .product-reviews-summary {
        margin-bottom: 0;
    }
}

.product-overview.grid {
    .product-item {
        margin-bottom: $indent__l;
    }
}

.products {
    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &.swiper-wrapper {
                flex-wrap: nowrap;
            }
        }
    }

    &-grid {
        @include make-row();
    }
}

.cart-price-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product {
    &-item {
        @extend .abs-add-box-sizing;
        box-sizing: border-box;
        padding-right: 8px;
        padding-left: 8px;
        vertical-align: top;
        width: calc(100% / 3);
        margin-bottom: 30px;

        .products-grid & {
            display: inline-block;
        }

        .products-grid &-name {
            @include text-truncate(15px);
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            margin-bottom: 5px;
        }

        &-info {
            max-width: 100%;
        }

        &-actions {
            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;
                    &::before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-final_price.price-box {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .price {
                white-space: nowrap;
            }

            .price-label {
                display: none; // Hide the price label
            }
        }

        .special-price,
        .minimal-price {
            margin: 0;

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
            order: 1;

            .price {
                color: $color-red14;
                font-size: 15px;
            }
        }

        .cart-price-wrapper.discount {
            .price-from {
                display: flex;

                .price-container.price-final_price {
                    order: 2;

                    .price {
                        color: $color-gray20;
                    }
                }

                .old-price {
                    order: 1;

                    .price-final_price {
                        .price {
                            font-weight: 500;
                            color: $color-gray20;
                        }
                    }
                }
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
            color: $color-gray20;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            @extend .btn--rounded;
            @include lib-icon-font(
                $icon-cart,
                $_icon-font-size: 18px,
                $_icon-font-text-hide: true
            );
            @include lib-icon-font(
                $icon-expand,
                $_icon-font-size: 10px,
                $_icon-font-margin: 2px 0 0 2px,
                $_icon-font-position: after
            );
            white-space: nowrap;
            padding: 8px 12px;

            &.added {
                @include lib-icon-font(
                    $icon-checkmark,
                    $_icon-font-position: after
                );
                background-color: $theme-color-green1;
                border-color: $theme-color-green1;
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;

            .stock {
                font-size: 14px;
            }
        }
    }
}

.products-grid {
    ol.products {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

.products-crosssell {
    margin-right: -18px;
    margin-left: -18px;

    .product-item {
        width: calc(100% / 4);
        padding-right: 18px;
        padding-left: 18px;
        margin-bottom: 6px;

        .product-item-name {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            height: 39px;

            a {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                line-height: 1.33;
            }
        }

        .price-box {
            margin-bottom: 12px;
        }
    }
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .weee {
        &::before {
            content: '(' attr(data-label) ': ';
        }

        &::after {
            content: ')';
        }

        + .price-excluding-tax {
            &::before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .photo-wrapper {
        position: relative;
        margin-right: 20px;
    }

    .product {
        &-item {
            width: 100%;

            &.item {
                padding-left: 0;
            }

            &-name {
                margin-top: 0;
            }

            &-info {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
            }

            &__bottom {
                flex-direction: column;
            }
        }

        .price-box {
            margin-bottom: 5px;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .products-grid {
        .products.product-items {
            .swatch-attribute {
                display: none;
            }
        }

        .product {
            &-item {
                @include make-col(12);
            }
        }
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product {
        &-item {
            .products-list &-name {
                font-size: 17px;
            }

            .product-overview &-name {
                @include text-truncate(15px);
            }

            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
