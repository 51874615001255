.product-labels {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $z-index__product-labels;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;

    .product-overview.products-list & {
        right: 0;
    }

    .product-overview.products-grid & {
        right: 8px;
    }

    .swiper-container & {
        right: 0;
    }

    .product.media & {
        right: 0;
    }
}

.product-label {
    position: absolute;
    padding: 5px 10px;
    left: 15px;
    top: 15px;
    box-sizing: border-box;
    max-width: 50%;
    margin-bottom: 20px;
    border-radius: 3px;
    line-height: 1;

    .product.media & {
        max-width: 30%;
    }

    span {
        position: relative;
        z-index: 5;
    }

    &--text {
        position: relative;
        max-width: 45%;
    }
}

.cart .item {
    .product-label {
        left: 12px;
        top: 32px;
        font-size: 10px;
        z-index: 1;
        max-width: 100px;
    }
}

@include max-screen($screen__m) {
    .cart .item {
        .product-label {
            left: 4px;
            top: 4px;
            max-width: 90px;
        }
    }
}
