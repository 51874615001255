$middle-bar-height      : 53px;

.navigation-menu {
    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-top-link {
        @include lib-link-all(
            $_link-color: $text__color
        );
        font-weight: $font-weight__medium;
    }

    &__sub-menu-container {
        display: none;
    }

    .no-parent > a,
    .parent.navigation-menu__sub-menu-column,
    .parent.navigation-menu__sub-menu-column--deepest {
        &::after {
            display: none;
        }
    }

    a.current.navigation-menu__sub-menu-link {
        color: $theme-color-pink;
    }
}

@include min-screen($screen__l) {
    .navigation-menu-container {
        @include lib-css(background-color, $header__navigation__background-color);
        position: relative;
    }

    .navigation-menu {
        &__list {
            display: flex;
            justify-content: space-between;
            overflow-x: auto;
            white-space: nowrap;
        }

        &__item {
            margin: 0;

            &:not(:last-of-type) {
                margin-right: 30px;
            }
        }

        &__top-link--home {
            a {
                @include lib-icon-font(
                    $_icon-font-content     : $icon-home,
                    $_icon-font-size        : 17px,
                    $_icon-font-position    : before,
                    $_icon-font-display     : block,
                    $_icon-font-line-height : 1
                );
            }
        }

        &__top-link:not(.all) {
            @include lib-link(
                $_link-color                   : $header__navigation__top-link-color,
                $_link-text-decoration         : none,
                $_link-color-visited           : $header__navigation__top-link-color,
                $_link-text-decoration-visited : none,
                $_link-color-hover             : $header__navigation__top-link-color,
                $_link-text-decoration-hover   : none,
                $_link-color-active            : $header__navigation__top-link-color,
                $_link-text-decoration-active  : none
            );
            position: relative;
            display: flex;
            align-items: center;
            height: $middle-bar-height - 1px;
            padding-right: 20px;
            padding-left: 20px;
            font-size: 14px;
            font-weight: $font-weight__medium;

            &:hover,
            &:focus,
            &:active {
                background-color: darken($theme-color-pink, 4%);
            }
        }

        &__top-link.all {
            display: none;
        }

        &__sub-menu-container {
            position: absolute;
            z-index: $z-index__megamenu;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            padding: 30px 0 70px;
            background-color: $color-white;
            border-top: 1px solid $border-color__base;
            border-bottom: 1px solid $border-color__base;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
        }

        &__sub-menu-row {
            @include make-row();
        }

        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(8);
            margin-bottom: 0;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &__sub-menu-list {
            margin-top: 5px;
        }

        &__top-linkall {
            display: none;
        }

        &__sub-menu-top-link,
        &__sub-menu-link {
            font-size: $small-font-size;
        }

        &__sub-menu-top-link {
            font-weight: $font-weight__semibold;
        }

        &__sub-menu-link {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }

        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            @include make-col(6);
            position: relative;
        }

        .main__inner-link + &__sub-menu-list {
            .navigation-menu__sub-menu-item {
                margin-bottom: 25px;
            }

            .navigation-menu__sub-menu-link {
                font-weight: $font-weight__bold;
            }
        }
    }
}

@include max-screen($screen__l) {
    .nav-sections {
        a {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }
    }

    .navigation-menu,
    .nav-sections .footer-menu {
        a {
            display: block;
            padding: $navigation-level0-item__padding;

            &:hover {
                text-decoration: none;
            }
        }

        &__item.parent {
            position: relative;
        }

        &__item {
            margin: 0;
        }

        &__top-linkall {
            margin-bottom: 10px;
        }
    }

    .navigation-menu {
        &__list {
            @include off-canvas-menu-item(
                $trigger: '.parent',
                $active: '.navigation-menu__item--active',
                $dropdown: '.navigation-menu__sub-menu'
            );

            @include off-canvas-menu-item(
                $trigger: '.navigation-menu__sub-menu-top-link',
                $active: '.navigation-menu__item--active',
                $dropdown: '.navigation-menu__sub-menu-list'
            );
        }

        &__sub-menu-top-link.navigation-menu__item--active + &__sub-menu-list {
            display: block;
        }
    }
}

//
//  Debugging
//  _____________________________________________

//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
