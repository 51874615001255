//
//  Shopping cart
//  ---------------------------------------------

.cart.table-wrapper {
    .action {
        margin-right: 15px;
    }

    .action.towishlist {
        @include lib-icon-font(
            $_icon-font-content: $icon-wishlist-full,
            $_icon-font-margin: -3px 3px 0 0,
            $_icon-font-size: 17px
        );
    }

    .action.action-delete {
        @include lib-icon-font(
            $_icon-font-content: $icon-trash,
            $_icon-font-margin: -4px 3px 0 0,
            $_icon-font-size: 17px
        );
    }

    .price-including-tax {
        .price {
            display: flex;
            order: 1;
            color: $theme-color-dark-gray3;
        }

        .cart-price {
            display: flex;
            margin-top: 10px;
            flex-direction: column;
            align-items: flex-end;
        }

        .old-price {
            display: flex;
            order: 2;
            margin: 4px 0 3px;

            span {
                text-decoration: line-through;
                font-size: 11px;
                font-weight: 500;
                color: $color-gray96;
            }

            & + .price {
                color: $color-red14;
            }
        }
    }
}

@include max-screen($screen__m) {
    .cart.table-wrapper {
        .actions-toolbar {
            text-align: left;
            margin-bottom: 15px;
        }

        .item-info {
            .product-item-photo {
                max-width: 100px;
            }
        }
    }

    .cart-container {
        .cart.table-wrapper {
            .item {
                .col.item {
                    padding: 25px 0 10px 120px;
                    margin-top: 23px;
                }
            }

            .col {
                padding-top: 25px;
            }
        }
    }

    .item-info {
        position: relative;

        .col.subtotal {
            position: absolute;
            right: 0;
        }
    }

    .cart-container {
        .form-cart {
            .action.continue {
                margin: 0 0 10px;
            }
        }
    }

    .cart.main.actions {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .cart.table-wrapper {
        margin-bottom: 10px;
    }

    .cart-container {
        display: flex;
        flex-direction: column;

        .cart-sidebar {
            order: 2;
        }

        .cart-form-wrapper {
            order: 1;
        }
    }
}

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    margin-bottom: $indent__m;
    padding: 15px;
    border: 1px solid $border-color__base;

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .total-discount {
        color: $color-red14;
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        > .primary {
            display: block;
            float: none;

            button {
                @include lib-button-responsive();
                @include lib-button-secondary();
                margin: 0;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .totals {
        @include lib-table(
            $_cell-padding-vertical: 7px,
            $_cell-padding-horizontal: 0
        );
    }

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;

            &:first-of-type {
                border-top: $border-width__base solid $border-color__base;
            }
        }
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                font-size: 16px;
                font-weight: $font-weight__semibold;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &::after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    @extend .abs-margin-for-blocks-and-widgets;

    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            @include lib-button-responsive();
            padding-right: 5px;
            padding-left: 5px;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 23%;
        padding: 20px;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }

            > .title {
                &::after {
                    right: 3px;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }

            .col.qty {
                white-space: nowrap;
                width: 1%;
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }

    .checkout-cart-index {
        .block-crosssell-container {
            padding-right: 27%;
        }
    }
}
