//
//  Variables
//  _____________________________________________

$minicart__border-color: $color-gray80 !default;
$minicart__padding-horizontal: $indent__base !default;

$minicart-qty__height: 24px !default;

//
//  Minicart
//  ---------------------------------------------

header {
    .minicart-items {
        .product-item-details {
            .old-price {
                .price {
                    color: $color-gray96;
                    font-size: 11px;
                }

                & + .price {
                    color: $color-red14;
                }
            }
        }
    }
}

.minicart-price {
    display: flex;

    .old-price {
        order: 2;

        .price {
            text-decoration: line-through;
        }
    }

    .price {
        margin-right: 5px;
        order: 1;
    }
}

.block-minicart {
    box-sizing: border-box;

    > div {
        position: relative;
        height: 100%;
    }

    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        padding: 15px 0;
        border-top: 1px solid $border-color__base;
        border-bottom: 1px solid $border-color__base;
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    @extend .abs-price;
                    font-size: 22px;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 15px;
            font-weight: $font-weight__light;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            margin-top: 15px;
            text-align: center;

            > .primary {
                margin: 0 0 15px;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }

    .product-item-details {
        .product-item-pricing {
            .price-including-tax {
                .minicart-old-price {
                    .price {
                        @extend .abs-price-md;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector: '.action.showcart',
        $_options-selector: '.block-minicart',
        $_dropdown-toggle-icon-content: $icon-cart,
        $_dropdown-toggle-active-icon-content: $icon-cart,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 23px,
        $_icon-font-line-height: 23px,
        $_icon-font-color: $minicart-icons-color
    );
    display: flex;
    align-items: center;
    float: right;

    .minicart-overlay {
        @extend .modals-overlay;
        @extend .transition-hidden;
    }

    &.active {
        .block-minicart {
            transform: translateX(0);
        }

        .minicart-overlay {
            @extend .transition-visible;
        }
    }

    .action.showcart {
        &.active {
            display: flex;

            &::before {
                color: $brand__primary__color;
            }
        }
    }

    .block-minicart {
        min-width: 416px;
        padding: 30px;
        z-index: 903;
        transition: .5s ease all;
        box-shadow: none;
        background-color: $color-white;
        border: 1px solid $border-color__base;
        top: calc(100% - 1px);
        right: 0;

        .block-title {
            @extend h4;
            margin-bottom: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid $border-color__base;

            &:hover {
                text-decoration: none;
            }

            strong {
                font-weight: 700;
            }
        }

        &::after {
            left: auto;
            right: 17px;
        }

        &::before {
            left: auto;
            right: 18px;
        }
    }

    .product {
        &-item {
            max-width: 100%;
            flex: 1 1 auto;
        }

        .actions {
            margin: -24px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }

    .action {
        &.close {
            position: absolute;
            top: -8px;
            right: 0;
            padding: 0;
            border: none;
        }

        &.showcart {
            z-index: 98;
            position: relative;
            white-space: nowrap;
            display: flex;
            align-items: center;

            .text {
                @extend .abs-visually-hidden;
            }

            .counter.qty {
                @extend .abs-number-badge;

                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        @include lib-css(max-width, $minicart-qty__height);
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }
}

header .minicart-items-wrapper {
    @include lib-css(margin, 0 (-$minicart__padding-horizontal) 20px);
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 0 20px;
}

header .minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        padding: 15px 0;
        /** BAB-333: fix width of minicart items. */
        width: 100%;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;

        > .product-image-photo {
            position: relative;
            z-index: 50;
        }
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .item > .product {
        display: flex;

        > .product-item-photo {
            margin-right: 15px;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 12px,
                $_icon-font-line-height: 12px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            position: relative;

            &::after {
                @include lib-css(color, $color-gray56);
                position: static;
                margin: 0 0 0 $indent__xs;
            }
        }
    }

    .product-item-name {
        @extend .product-item-name;
        @include text-truncate(15px, $fixed-height: false);
        font-weight: 500;
        margin: 0 0 5px;
        line-height: 1.3;

        .product-qty {
            &::after {
                content: 'x';
            }
        }
    }

    .product-item-details {
        flex: 1;

        .price {
            @extend .abs-price-md;
            line-height: 1.5;
        }

        .special-price .price {
            margin-right: 0;
        }

        .weee[data-label] {
            @include lib-font-size(11);

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }

        .secondary {
            text-align: right;
        }
    }

    .product-options {
        font-size: 14px;

        &__list {
            margin-bottom: 5px;

            span {
                display: block;
                line-height: 1.3;
            }
        }

        .tooltip.toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-margin: -3px 0 0 7px,
                $_icon-font-position: after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        @include lib-font-size(11);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.delete {
            font-size: 14px;
            text-decoration: underline;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .minicart-wrapper {
        .action.showcart {
            margin-left: 0;
        }

        .block-minicart {
            width: 100%;
            padding: 25px;

            &::after {
                left: auto;
                right: 1px;
            }

            &::before {
                left: auto;
                right: 2px;
            }

            .block-title {
                padding-bottom: 20px;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .minicart-wrapper {
        .block-minicart {
            min-width: 300px;
        }
    }
}

@include min-screen($screen__m) {
    header .minicart-items {
        .values span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 210px;
        }
    }
}

@include min-screen($screen__s) {
    header .minicart-items {
        .product-item-flex {
            display: flex;
            justify-content: space-between;
        }

        .product-item-pricing {
            margin-left: 10px;
        }
    }
}

@include max-screen($screen__s) {
    header .minicart-items {
        .product-item-pricing {
            margin-top: 20px;
        }
    }
}
