//
//  General
//  _____________________________________________

.price-box {
    line-height: 1;

    .price {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .price-to {
        display: none;
    }

    .price-from {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        .price-label {
            color: $color-gray20;
            font-size: $lead-font-size;
        }
    }

    .price-container {
        display: flex;
        flex-direction: column;

        .price {
            margin-bottom: 0;
        }

        .price-label {
            margin-right: 5px;
            font-size: 15px;
            font-weight: $font-weight__medium;
            margin-bottom: 2px;
        }
    }
}

//
//  Detail page
//  _____________________________________________

.product-info-price {
    margin-bottom: 20px;

    .price {
        margin-right: 30px;
    }

    .price-label {
        display: inline-block;
    }

    .price-box {
        .special-price {
            .price {
                color: $color-red14;
            }
        }

        .old-price {
            .price {
                font-size: 24px;
                margin-top: 13px;
                font-weight: $font-weight__book;
            }
        }
    }
}

.traffic-light {
    margin-left: 55px;
}

@include max-screen($screen__m) {
    .product-info-price {
        .price-box {
            row-gap: 10px;

            .old-price {
                .price {
                    font-size: 15px;
                    margin-top: 7px;
                }
            }
        }
    }
}

//
//  Small (old-prices)
//  _____________________________________________

.price-box {
    .old-price,
    .normal-price {
        @extend .abs-price-sm;
    }

    .old-price {
        .price {
            font-size: 11px;
            color: $color-gray96;
            font-weight: $font-weight__light;
            text-decoration: line-through;
        }
    }
}

//
//  Medium (regular)
//  _____________________________________________

.price-box {
    > .price-final_price,
    .special-price,
    .normal-price,
    .minimal-price {
        .price {
            @extend .abs-price-md;
        }
    }
}

//
//  Extra Large (Detail page)
//  _____________________________________________

.product-info-price {
    .price-box {
        > .price-final_price,
        .special-price,
        .normal-price,
        .minimal-price,
        .price-from {
            .price {
                @extend .abs-price-xl;
            }
        }
    }
}
