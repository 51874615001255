.cart-popup {
    &__inner {
        margin-bottom: 20px;
    }

    &__image {
        width: 100px;
    }

    &__product-title {
        @extend .product-item-name;
        font-size: 17px;
    }

    &__price {
        display: flex;
        align-items: center;

        @extend .abs-price-md;

        .price {
            order: 1;
        }

        .old-price {
            font-size: 11px;
            color: $color-gray96;
            text-decoration: line-through;
            order: 2;

            & + .price {
                margin-right: 5px;
                line-height: 1;
                color: $color-red14;
            }
        }
    }
}

@include min-screen($screen__m) {
    .cart-popup {
        &__inner {
            display: flex;
        }

        &__image {
            margin-right: 20px;
        }
    }
}
