.header-top-bar {
    @include lib-css(display, $top-bar__display);
    background-color: $color-white-smoke;
    padding: 9px 0;
    font-size: 14px;
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    @extend .abs-usps-horizontal;
    width: 100%;

    ul {
        display: flex;
        justify-content: space-between;

        li {
            margin-right: 0;
            font-size: 12px;
            color: $color-gray20;
            padding-left: 20px;
            line-height: 1;

            &:before {
                color: $theme-color-green2;
            }
        }
    }
}

.header-top-bar-mobile {
    height: 33px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #d8dbe5;

    ul {
        padding: 0;
        margin: 0;

        li {
            margin: 10px 0;
            display: block;
            text-align: center;
            font-size: 13px;
            color: #333;

            @include lib-icon-font(
                $_icon-font-content: $icon-success,
                $_icon-font-size: 13px,
                $_icon-font-color: $theme-color-green2,
                $_icon-font-margin: 0px 5px 0 0
            );


            &:nth-of-type(1) {
                animation: fadeinout 4s linear forwards;
            }

            &:nth-of-type(2) {
                z-index: 10;
                opacity: 0.2;
            }

            &:nth-of-type(n+2) {
                display: none;
            }

            @keyframes fadeinout {
                0% {
                    opacity: 0;
                }

                25%, 50%, 75% {
                    opacity: 1;
                }
            }

        }
    }
}

@include max-screen($screen__l) {
    .header-phonenumber {
        display: none;
    }
}

@include max-screen($screen__m) {
    .header-top-bar {
        display: none;
    }

    .header-top-bar-mobile {
        display: flex;
    }
}
