.bundle-actions {
    @extend .abs-box-tocart;

    .action.primary {
        @extend .abs-button-l;
    }
}

.bundle-options-container {
    width: 100%;
    display: inline-block;
    margin-bottom: $indent__xl;

    .legend.title {
        @include lib-heading(h2);
    }

    .product-add-form {
        display: none;
    }

    .input-text.qty {
        @extend .abs-input-qty;
    }

    .product-options-wrapper {
        margin-bottom: $indent__l;
    }

    .action.back {
        margin-bottom: $indent__l;
    }

    .box-tocart {
        .fieldset {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .price-box {
        .price {
            font-size: $font-size__l;
            font-weight: $font-weight__bold;
        }
    }

    .price-notice {
        @extend .abs-adjustment-incl-excl-tax;
    }

    .block-bundle-summary {
        @extend .abs-add-box-sizing;
        @include lib-css(background, $secondary__color);
        padding: 40px;

        > .title > strong {
            @include lib-heading(h2);
        }

        .bundle-summary {
            margin-top: $indent__l;

            &.empty {
                display: none;
            }

            > .subtitle {
                @include lib-heading(h3);
                display: block;
            }
        }

        .bundle.items {
            @extend .abs-reset-list;

            > li {
                margin-bottom: $indent__s;
            }
        }

        .box-tocart {
            .actions {
                display: inline-block;
                margin-bottom: $indent__s;
            }

            .action.primary {
                @extend .abs-button-l;
            }
        }

        .product-addto-links {
            > .action {
                @extend .abs-actions-addto;
                vertical-align: top;
            }
        }
    }

    .nested {
        .field.qty {
            .label {
                @include lib-css(font-weight, $form-field-label__font-weight);
                @include lib-css(margin, 0 0 $indent__xs);
                display: inline-block;
            }

            @include lib-css(margin-top, $form-field__vertical-indent);
        }
    }

    p.required {
        @include lib-css(color, $form-field-label-asterisk__color);
    }

    .product-options-bottom {
        clear: left;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .bundle-options-container {
        .legend.title {
            @extend .abs-reset-left-margin-desktop;
        }

        .bundle-options-wrapper,
        .product-options-wrapper {
            float: left;
            width: 57%;
        }

        .block-bundle-summary {
            float: right;
            position: relative;
            width: 40%;
        }

        .bundle-options-wrapper,
        .block-bundle-summary {
            .page-layout-2columns-left &,
            .page-layout-2columns-right &,
            .page-layout-3columns & {
                width: 48%;
            }
        }
    }
}
