.our-locations {
    &__intro {
        h1, h2, h3, h4 {
            font-size: 28px;
            margin-bottom: 24px;
        }

        font-size: 14px;
    }

    &__locations {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        margin-bottom: 48px;
    }

    &__location {
        display: flex;
        border-radius: 5px;

        &-image {
            display: flex;
            justify-content: center;
            border-radius: 5px 0 0 5px;
            overflow: hidden;
            min-width: 254px;

            img {
                max-width: unset;
                height: 100%;
            }
        }

        &-hours {
            color: $theme-color-green1;

            &:before {
                content: '';
                margin-right: 6px;
                width: 10px;
                height: 10px;
                display: inline-flex;
                background: $theme-color-green1;
                border-radius: 50%;
            }
        }

        &-address, &-hours {
            line-height: 2;
        }

        &-content {
            font-size: 14px;
            width: 100%;
            color: $theme-color-dark-gray3;
            padding: 24px 36px;
            border-radius: 0 5px 5px 0;
            border-top: 1px solid #d9dbe4;
            border-right: 1px solid #d9dbe4;
            border-bottom: 1px solid #d9dbe4;
        }

        &-link {
            margin-top: 10px;
            color: #b0528d;
            text-decoration: underline;

            &:hover {
                color: darken(#b0528d, 10%);
            }
        }
    }

    #map {
        width: 100%;
        height: 480px;
        margin-bottom: 40px;

        .gmnoprint, .gmnoscreen {
            display: none;
        }
    }
}

.store-location {
    font-size: 14px;
    margin-bottom: 90px;

    &__title {
        font-size: 28px;
    }

    &__store-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        row-gap: 30px;
        padding-bottom: 60px;
        margin-bottom: 60px;
        border-bottom: 1px solid #d9dbe4;
    }

    &__map {
        #map {
            width: 100%;
            height: 254px;
        }
    }

    &__address {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 24px;

        &-phone {
            display: block;
            margin-top: 7px;
        }

        &-links {
            a {
                text-decoration: underline;

                &:not(:last-child) {
                    margin-right: 18px;
                }
            }
        }
    }

    &__hours, &__special-hours {
        table {
            tr {
                &:not(:last-child) {
                    border-bottom: 1px solid #d9dbe4;
                }

                td {
                    vertical-align: middle;
                    line-height: 1;
                    padding: 16px 20px;

                    .mobile {
                        display: none;
                    }

                    &:last-of-type {
                        font-weight: 600;
                        color: #3e3f42;
                    }
                }

                &.active {
                    td {
                        font-weight: 600;
                        color: $theme-color-green1;
                    }
                }
            }
        }
    }

    &__address, &__hours, &__special-hours {
        background-color: $color-gray-light0;
        padding: 30px 24px;
    }

    &__content-section {
        display: grid;
        font-size: 15px;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        &-image {
            flex: 1;

            img {
                display: block;
                width: 100%;
            }
        }

        &-text {
            flex: 1;
        }

        &:not(:last-of-type) {
            margin-bottom: 60px;
        }

        &:first-of-type {
            h2 {
                font-size: 28px;
                line-height: 36px;
                margin-bottom: 18px;
            }
        }

        &:nth-of-type(even) {
            .store-location__content-section-text {
                order: 2;
                padding-left: 95px;
            }
        }

        &:nth-of-type(odd) {
            .store-location__content-section-text {
                padding-right: 95px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .our-locations {
        &__locations {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 24px;
        }

        &__location {
            &-image {
                min-width: 113px;
            }

            &-title {
                font-size: 16px;
                margin-bottom: 0;
            }

            &-address {
                line-height: 1.5;
            }

            &-hours {
                margin-bottom: 5px;
                line-height: 1.4;
            }

            &-content {
                padding: 24px 20px;
            }
        }

        #map {
            margin-bottom: 20px;
        }
    }

    .store-location {
        margin-bottom: 70px;

        &__title {
            margin-bottom: 20px;
        }

        &__store-info {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            padding-bottom: 40px;
            margin-bottom: 40px;
        }

        &__address {
            h3 {
                margin-bottom: 15px;
            }

            &-links {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                a:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }

        &__hours {
            padding: 25px 24px 0;

            tr {
                td {
                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:nth-of-type(2) {
                        display: none;
                    }

                    &:last-of-type {
                        padding-right: 0;
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                &:last-of-type {
                    td:last-of-type {
                        text-align: end;
                        line-height: 1.3;
                    }
                }
            }
        }

        &__special-hours {
            padding: 25px 24px 0;

            table {
                tr {
                    td {
                        &:first-of-type {
                            padding-left: 0;
                        }

                        &:nth-of-type(2) {
                            padding-right: 0;
                            text-align: right;
                        }

                        &:last-of-type {
                            display: none;
                        }

                        .mobile {
                            display: block;
                            font-weight: 600;
                            color: #3e3f42;
                            margin-top: 10px;
                        }
                    }

                    &:last-of-type {
                        td:last-of-type {
                            text-align: end;
                            line-height: 1.3;
                        }
                    }
                }
            }
        }

        &__content {
            &-section {
                display: flex;
                flex-direction: column;

                &:not(:last-of-type) {
                    margin-bottom: 40px;
                }

                &:nth-of-type(odd), &:nth-of-type(even) {
                    .store-location__content-section-text {
                        order: 1;
                        padding: 0;

                        p:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    .store-location__content-section-image {
                        order: 2;
                        width: 100%;
                    }
                }
            }
        }
    }
}
