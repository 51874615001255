$footer__background-color                  : false !default;

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
        font-size: 14px;
    }

    &__sub-menu-topall {
        display: none;
    }
}

.footer-bottom {
    margin-bottom: 20px;
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    display: block;
    font-size: 12px;
    line-height: 1;
}

.footer-payment-providers {
    display: flex;
    gap: 12px;
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;

        button {
            border-radius: 0;
        }

        .newsletter-text {
            h4 {
                font-weight: $font-weight__bold;
            }
        }

        #newsletter-error.mage-error {
            position: absolute;
            margin-top: 50px;
            color: red;
        }
    }

    p {
        margin-bottom: 20px;
    }

    .social-media {
        margin-top: 25px;
    }
}

.top-footer {
    margin-bottom: 40px;
    padding: 17px 0;
    background-color: $brand__primary__color;
    color: $color-white;

    &__left {
        margin: 5px 15px 5px 0;
        font-size: 19px;
        font-weight: $font-weight__bold;
    }

    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
        }

        li {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-size: 22px
            );
            margin: 0;
            font-weight: $font-weight__medium;

            &:nth-child(1) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-call
                );

                &::before {
                    font-size: 20px;
                }
            }

            &:nth-child(2) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-euro
                );
            }

            &:nth-child(3) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-store
                );

                &::before {
                    font-size: 22px;
                }
            }

            &:not(:first-child) {
                margin-left: 25px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        @include make-row();
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 50px;

        .kiyoh-widget {
            transform: translateY(-15px);
        }

        .footer-menu {
            display: flex;
            gap: 80px;

            &__item {
                flex: 1;
            }

            &__top-link {
                @include lib-heading(h4);
                @include lib-link-all(
                    $_link-color: $theme-heading-color
                );
                font-weight: $font-weight__bold;
                margin-bottom: 13px;
                display: inline-block;
                white-space: nowrap;
            }

            &__sub-menu-link {
                @include lib-link-all(
                    $_link-color: $text__color
                );
            }

            &__sub-menu-item {
                margin-bottom: 8px;
                line-height: 1.2;
            }
        }
    }

    .footer-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #d9dbe4;
        padding-top: 30px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        margin-bottom: 60px;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }

    .footer-copyright {

    }
}


@include screen($screen__m, 1060px) {
    .footer-columns {
        justify-content: unset;
        margin-left: 0;
        margin-right: 0;

        .footer-menu-container {
            order: 1;
            width: 100%;
            margin-left: 15px;
        }

        .footer-menu__item {
            flex: unset;
        }

        .kiyoh-widget {
            order: 2;
        }

        .after-footer-columns {
            order: 3;
            margin-left: 59px;
        }
    }
}


//
//  Mobile
//  _____________________________________________


@include max-screen($screen__m) {
    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;

        &__item {
            position: relative;
            margin: 0;
            padding: 30px 35px 15px $layout-indent__width;
            border-bottom: 1px solid $border-color__base;

            &:first-child {
                border-top: 1px solid $border-color__base;
            }
        }

        &__top-link {
            margin-bottom: 10px;
        }

        &__sub-menu-topall {
            display: none;
        }
    }

    .footer-menu {
        li {
            margin-bottom: 0;

            a {
                padding: 5px 0;
            }
        }
    }

    .footer-columns {
        margin-bottom: 30px;

        .kiyoh-widget {
            border-top: 1px solid #d9dbe4;
            padding-top: 10px;
            margin-left: -16px;
            margin-right: -16px;
            text-align: center;

            iframe {
                max-width: 100%;
                width: 320px;
            }
        }

        .block.newsletter {
            margin-top: 20px;

            #newsletter-error.mage-error {
                position: absolute;
                margin-top: 50px;
                color: red;
            }
        }

        .social-media {
            margin-top: 25px;
        }
    }

    .block.newsletter {
        .form.subscribe {
            flex-wrap: wrap;
        }

        .field.newsletter {
            width: 100%;
            height: 46px;
            margin-bottom: 10px;
        }

        .actions {
            width: 100%;

            .action {
                width: 100%;
            }
        }
    }

    .footer-copyright {
        order: 2;
        text-align: center;
    }

    .top-footer {
        display: none;
    }

    .footer-payment-providers {
        flex-wrap: wrap;
        width: 276px;
        margin: 0 auto 20px auto;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #d9dbe4;
        padding-top: 30px;
        margin-bottom: 60px;
        margin-right: -16px;
        margin-left: -16px;
    }
}
