.sidebar .filter-title {
    display: block;
    transform: translateX(-100%);
    height: 48px;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: $color-white;
    transition: transform 0.2s;
    font: '0px/0' a;
    color: transparent;

    .filter-active & {
        transform: translateX(0);
    }

    strong {
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-color: $text__color__muted,
            $_icon-font-size: 16px,
            $_icon-font-position: after
        );
        position: absolute;
        right: 15px;
        top: 14px;
        left: auto;
        padding: 0;
        border: 0;
        box-shadow: none;
    }
}

.sidebar {
    .filter-options-content {
        display: none;
    }

    .filter-options-content[aria-hidden="false"] {
        display: block;
    }
}

body:not(.page-with-filter) {
    .block.filter {
        display: none;
    }
}

.navigation-overlay {
    position: absolute;
    top: -78px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .4;
    z-index: 1000000;

    >i {
        font-size: 3rem;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.m-navigation-filter-item {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: -3px;
    margin-bottom: -3px;
}

@include max-screen($screen__l) {
    .sidebar .filter-title {
        width: $filter-menu-width-m;
    }
}

@include max-screen($screen__m) {
    .sidebar .filter-title {
        width: $filter-menu-width-s;
    }

    .block.filter {
        margin: 0;
    }
}
