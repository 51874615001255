.m-navigation-slider .ui-slider {
  position: relative;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.m-navigation-slider .ui-slider-handle {
  position: relative;
  z-index: 1;
}
.m-navigation-slider .ui-slider-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.m-navigation-slider .ui-slider-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}
.m-navigation-slider .ui-slider {
  background: #3FB8AF;
  border-radius: 4px;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
.m-navigation-slider .ui-slider-handle {
  border-radius: 50%;
  background: $brand__primary__color;
  cursor: default;
}
/* Handle stripes;
 */
.m-navigation-slider .ui-slider-handle:before,
.m-navigation-slider .ui-slider-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.m-navigation-slider .ui-slider-handle:after {
  left: 17px;
}

/* Disabled state;
 */
[disabled] .m-navigation-slider .ui-slider {
  background: #B8B8B8;
}

[disabled].m-navigation-slider .ui-slider-handle,
[disabled] .m-navigation-slider .ui-slider-handle {
  cursor: not-allowed;
}

.m-navigation-slider .ui-slider {
  background: $brand__primary__color;
  box-shadow: none;
  height: 6px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 8px;
  padding-right: 8px;
}
.m-navigation-slider .ui-slider-handle:after,
.m-navigation-slider .ui-slider-handle:before{
  display: none;
}

.m-navigation-slider .ui-slider-handle {
  width: 18px;
  height: 18px;
  left: 0;
  top: -6px;
}

.m-navigation-slider a {
  margin: 0px;
  padding: 0px;
}

.m-navigation-slider .ui-slider-handle {
  display: inline-block;
}

.m-navigation-slider-price {
  width: 98%;
  padding-left: 0px;
}

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -12px;
}

.from-to-box {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $border-color__base;
  border-width: 1px 0 0 1px;

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-right: 1px solid $border-color__base;
    border-bottom: 1px solid $border-color__base;
  }

  &__input {
    text-align: right;
    font-weight: $font-weight__medium;
    font-size: 14px;
    color: #878787;

    &[readonly]:focus {
      box-shadow: none;
    }
  }

  &__label {
    margin-right: 5px;
    text-transform: uppercase;
    color: #aeaeae;
    font-size: 11px;
    letter-spacing: 0.04em;
    font-weight: $font-weight__medium;
  }
}
