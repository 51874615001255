//
//  Variables
//  _____________________________________________

$review-ratings-left: 280px !default;
$reviews-indent-desktop: 20px !default;

.data.switch .counter {
    @include lib-css(color, $text__color__muted);

    &::before {
        content: '(';
    }

    &::after {
        content: ')';
    }
}

.catalog-product-view {
    .page-sub-title {
        @include lib-rating-summary(
                $_icon-font-size: 14px,
                $_icon-letter-spacing: 2px
        );

        .product-reviews-summary {
            .reviews-actions {
                a {
                    font-size: 14px;
                    text-transform: lowercase;

                    &.js-add-review {
                        @include lib-link-all(
                            $_link-color: $brand__primary__color
                        );
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.rating-summary {
    @include lib-rating-summary();
}

.product-reviews-summary,
.table-reviews,
.review-list {
    .rating-summary {
        @include lib-rating-summary-label-hide();
    }
}

.review-control-vote {
    @include lib-rating-vote();

    &::before {
        @include lib-rating-icons-content(
            $_icon-content: $icon-star
        );
    }
}

//
//  Empty ratings
//  ---------------------------------------------

.no-rating {
    .rating-result::before {
        @include lib-rating-icons-content(
            $_icon-content: $icon-star-empty
        );
    }
}

//
//  Add review block
//  ---------------------------------------------

.review-form-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn--primary {
        @include lib-icon-font(
            $_icon-font-content: $icon-edit,
            $_icon-font-margin: 0 10px 0 -3px,
            $_icon-font-size: 18px
        );
        margin: 0;
        padding: 9px 15px;
        font-size: 14px;
    }
}

.more-reviews-btn {
    @include lib-button-as-link();
    @include lib-icon-font(
        $_icon-font-content: $icon-update,
        $_icon-font-margin: 0 4px 0 0,
        $_icon-font-size: 17px
    );
    color: $brand__primary__color;
}

.block {
    &.add.review {
        margin-bottom: $indent__xl;

        > .title {
            @include lib-heading(h2);
        }

        .legend {
            @include lib-heading(h3);
            margin: 0 0 $indent__base;
        }

        .field.rating {
            display: table-row;

            .label {
                display: table-cell;
                padding: $indent__xs 0;
                vertical-align: middle;
            }

            .control {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    &.reviews.list {
        > .title {
            @include lib-heading(h2);
        }

        .review {
            &.item {
                margin-bottom: $indent__xl;
            }

            &.title {
                @include lib-heading(h3);
            }
        }
    }
}

.data.table.reviews {
    .rating-summary {
        margin-top: -4px;
    }
}

.customer-review.view {
    margin-bottom: $indent__base;

    .review.title,
    .ratings-summary,
    .product-details {
        margin-bottom: $indent__base;
    }
}

.product-reviews-summary {
    @extend .abs-margin-for-product-elements;
    display: flex;
    align-items: center;
    line-height: 1;

    &.empty {
        margin-left: 0;
    }

    .rating-summary {
        display: inline-block;
        vertical-align: middle;
        margin-right: $indent__s;
    }

    .text-link {
        margin-right: $indent__s;
    }

    .reviews-actions {
        display: inline-block;
        margin-top: 3px;
        line-height: $rating-icon__font-size;
        vertical-align: middle;

        a:not(:last-child) {
            margin-right: 30px;
        }
    }
}

.review-add {
    margin-top: $indent__l;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .more-reviews-btn {
        margin-right: 10px;
    }

    .review-add-wrapper {
        margin-bottom: 60px;
    }

    .review-add {
        border: 1px solid $border-color__base;
        padding: 25px;
    }
}

.customer-review {
    .product-details {
        @extend .abs-add-clearfix;
        margin-bottom: $indent__xl;

        .rating-average-label {
            @extend .abs-visually-hidden;
        }
    }

    .product-media {
        float: left;
        margin-right: 3%;
        max-width: 285px;
        width: 30%;
    }

    .review-details {
        .customer-review-rating {
            @include lib-css(margin-bottom, $indent__base);
            @extend .abs-rating-summary;

            .item {
                @include lib-css(margin-bottom, $indent__s);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .review-title {
            @include lib-heading(h3);
            @include lib-css(font-weight, $font-weight__semibold);
            @include lib-css(margin-bottom, 0);
        }

        .review-content {
            @include lib-css(margin-bottom, $indent__base);
        }

        .review-date {
            @include lib-css(color, $primary__color__light);
        }
    }
}

//
//  Review product page
//  ---------------------------------------------

.review {
    &-toolbar {
        border-top: 1px solid $color-gray79;
        margin: 0 0 $indent__base;
        text-align: center;

        &:first-child {
            display: none;
        }

        .pages {
            border-bottom: 1px solid $color-gray79;
            display: block;
            padding: $indent__base 0;
            text-align: center;
        }

        .limiter {
            display: none;
        }
    }

    &-add {
        display: none;

        .block-title {
            display: none;
        }
    }

    &-form {
        .action.submit.primary {
            @include lib-button-revert-secondary-color();
        }
    }

    .fieldset &-legend.legend {
        @extend .abs-reset-left-margin;
        margin-bottom: $indent__base;

        strong {
            display: block;
            margin-left: 0;
        }
    }

    &-field-rating {
        .control {
            margin-bottom: 1.2 * $indent__xl;
            margin-top: $indent__s;
        }
    }

    &-list {
        margin-bottom: $indent__l;

        .block-title strong {
            @include lib-heading(h2);
        }
    }

    &-item {
        @include lib-clearer();
        border-bottom: 1px solid $border-color__base;
        margin: 0;
        padding: $indent__base 0;

        &:first-child {
            padding-top: 0;
        }
    }

    &-ratings {
        @extend .abs-rating-summary;
        display: table;
        margin-bottom: $indent__s;
        margin-right: $indent__s;
        max-width: 100%;
        line-height: 1;
    }

    &-author {
        display: inline;
    }

    &-info {
        display: flex;
        align-items: flex-start;
        margin: 0 0 $indent__base;
    }

    &-title {
        @include lib-heading(h4);
        margin: 0;
    }

    &-content {
        margin-bottom: $indent__base;
    }

    &-details {
        display: flex;
        color: $theme-text-color-lighter;
    }

    &-date {
        &::before {
            content: ' ';
            display: inline-block;
        }
    }

    &-control-vote label:before,
    &-control-vote:before {
        font-size: 32px;
        height: 32px;
        letter-spacing: 10px;
        line-height: 32px;
    }
}

.review-count {
    display: inline-block;
    margin-top: 3px;
    @include lib-css(color, $text__color__muted);
    vertical-align: middle;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .review {
        &-ratings {
            margin-bottom: 0;
            max-width: $review-ratings-left - $indent__xl;
        }

        &-toolbar {
            margin: 0 0 $reviews-indent-desktop;

            .pages {
                padding: $reviews-indent-desktop 0;
            }
        }

        .fieldset &-legend.legend {
            margin-bottom: $reviews-indent-desktop;
        }

        &-item {
            padding: $reviews-indent-desktop 0;
        }
    }
}

@include min-screen($screen__m) {
    .product-reviews-summary {
        .reviews-actions {
            font-size: $font-size__base;
        }
    }
}

@include max-screen($screen__m) {
    .review-form-header {
        flex-direction: column;

        .btn {
            margin-top: 15px;
        }
    }
}
