.homepage-seo {
    &__content {
        display: flex;
        justify-content: space-between;
        column-gap: 50px;

        &-section {
            flex: 1;
        }
    }

    &__title {
        margin-bottom: 40px;
    }

    margin-bottom: 40px;
}

.main {
    .megastore-image {
        width: 100%;
    }

    .google-maps-elem {
        display: block;
    }
}

@include max-screen($screen__m) {
    .homepage-seo {
        &__content {
            flex-direction: column;

            &-section {
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.social-media {
    &__anchor {
        &.tiktok {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-tiktok
            );
        }
    }
}

@include max-screen($screen__l) {
    .main {
        .google-maps-elem {
            display: none;
        }
    }
}

.related {
    .towishlist {
        padding-top: 5px;
    }
}

#opc-sidebar {
    .usp-wrapper {
        margin: 0 0 20px;

        .usp {
            display: flex;
            flex-direction: column;

            div:nth-child(odd) {
                border: 1px solid $theme-border-color;
            }

            div:nth-child(even) {
                border-left: 1px solid $theme-border-color;
                border-right: 1px solid $theme-border-color;
            }

            div {
                padding: 10px 20px;
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 20px;
                    margin-right: 10px;
                }
                span {
                    line-height: normal;
                    transform: translateY(1px);
                }
            }
        }
    }
}

.cart-sidebar {
    display: flex;
    flex-direction: column;
    top: 10px;

    .usp-wrapper {
        .usp {
            display: flex;
            flex-direction: column;

            div:nth-child(odd) {
                border: 1px solid $theme-border-color;
            }

            div:nth-child(even) {
                border-left: 1px solid $theme-border-color;
                border-right: 1px solid $theme-border-color;
            }

            div {
                padding: 10px 20px;
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 20px;
                    margin-right: 10px;
                }

                span {
                    line-height: normal;
                    transform: translateY(1px);
                }
            }
        }
    }
}

.checkout-payment-method {
    .payment-method-title {
        .payment-icon {
            max-height: 32px;
        }
    }
}

@include min-screen($screen__m) {
    .cart-summary {
        width: 100%;
        order: 1;
    }

    .cart-sidebar {
        position: sticky;
        float: right;
        width: 23%;

        .usp-wrapper {
            order: 2;

        }
    }
}

@include max-screen($screen__m) {
    .cart-summary {
        width: 100%;
        order: 2;
    }

    .cart-sidebar {
        .usp-wrapper {
            order: 1;
            margin-bottom: 20px;
        }
    }
}
