.daily-deal-widget {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__deal-title {
        font-size: 23px;
        font-weight: $font-weight__bold;
    }

    &__sub-title {
        margin-bottom: 3px;
        color: $brand__primary__color;
        font-size: 15px;
        font-weight: 400;
    }

    &__image {
        display: block;
        margin-right: 65px;
    }

    &__image-anchor {
        position: relative;
        display: block;
    }

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__anchor {
        @include lib-button-primary();
        align-self: flex-start;
        padding: 6px 16px;
        border-radius: 19px;
        white-space: nowrap;
    }

    &__product-name {
        @include text-truncate(15px, $fixed-height: false);
        margin-bottom: 11px;
    }

    &__price {
        @extend .abs-margin-for-product-elements;
        display: flex;
        align-items: center;
        line-height: 1;
    }

    &__special-price {
        @extend .abs-price-lg;
        color: $discount__color;
        font-weight: $font-weight__semibold;
    }

    &__old-price {
        @extend .abs-price-sm;
        margin: 2px 0 0 10px;
        text-decoration: line-through;
        font-size: 14px;
    }

    &__discount-label {
        box-sizing: border-box;
        position: absolute;
        color: white;
        background-image: url('../images/widget-discount-background.png');
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: 500;
        line-height: 1;
        left: -20px;
        top: 20px;
        width: 84px;
        height: 84px;
        padding: 10px;
        font-family: $font-family__accent;

        &-text {
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}

.sidebar .daily-deal-widget {
    padding: 30px 15px;

    &__sub-title {
        font-size: 14px;
        margin-bottom: 15px;
    }

    &__deal-title {
        font-size: 19px;
    }

    &__content {
        display: block;
    }

    &__discount-label {
        display: none;
    }

    &__image {
        max-width: 120px;
        margin: 0 auto 15px;
    }

    &__product-name {
        text-align: center;
    }

    &__anchor {
        font-size: 14px;
        margin: 0 auto;
    }

    &__price {
        margin-bottom: 15px;
        justify-content: center;
    }

    &__special-price {
        font-size: 19px;
    }
}

@include min-screen($screen__s) {
    .daily-deal-widget {
        padding: 15px;
        border: 1px solid $border-color__base;

        &__content {
            margin: 15px auto;
        }

        &__title {
            text-align: center;
        }

        .timer {
            justify-content: center;
        }
    }
}

@include min-screen($screen__m) {
    .daily-deal-widget {
        margin-bottom: 20px;

        &__content {
            margin: 0 auto;
        }
    }
}

@include min-screen($screen__xl) {
    .daily-deal-widget {
        &__content {
            max-width: 70%;
            margin: 15px auto;
        }

        &__product-name {
            @include text-truncate(15px, $fixed-height: false);
        }
    }

    .sidebar .daily-deal-widget {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;

        &__image {
            margin: 0 auto 15px;
        }

        &__content {
            max-width: none;
            margin: 10px 0 0;
        }
    }

    .timer {
        padding-top: 12px;
    }
}

@include max-screen($screen__l) {
    .daily-deal-widget {
        justify-content: space-between;

        &__discount-label {
            transform: scale(0.6);
        }

        &__image {
            max-width: 100px;
        }

        &__sub-title {
            display: none;
        }
    }
}

@include max-screen($screen__s) {
    .daily-deal-widget {
        padding: 30px 16px;
        margin-left: -16px;
        margin-right: -16px;
        border-top: 1px solid $border-color__base;
        border-bottom: 1px solid $border-color__base;

        &__image-anchor {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        &__title {
            order: 1;
        }

        .timer {
            order: 2;
        }

        &__content {
            order: 3;
        }
    }
}
