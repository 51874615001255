.breadcrumbs {
    @include lib-breadcrumbs();
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .breadcrumbs {
        margin: 0 0 15px;
        
        .items {
            flex-wrap: wrap;
        }
    }
}
