@import "module/multiselect";
@import "module/slider";
@import "module/rating";
@import "module/horizontal";
@import "module/global";
@import "module/show_more";
//@import "awesome-checkbox/build";
//@import "awesome-checkbox/font-awesome";


.navigation-overlay {
    opacity: 1;
    background: rgba(255, 255, 255, .4);

    .blueprint-loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
