.pages {
    @include lib-pager();

    .items {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    .page {
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        line-height: 1;
    }

    .action {
        &.previous {
            margin-right: 10px;
        }

        &.next {
            margin-left: 10px;
        }

        &::before {
            line-height: 1;
        }
    }

    .pages-items {
        .pages-item-next,
        .pages-item-previous {
            a {
                display: flex;
                align-items: center;
            }
        }
    }
}
