//
//  Rating variables
//  _____________________________________________

$rating-icon__count          : 5 !default;
$rating-icon__content        : $icon-star !default;
$rating-icon__font           : $icon-font !default;
$rating-icon__font-size      : 12px !default;
$rating-icon__letter-spacing : 3px !default;
$rating-icon__color          : $color-gray78 !default;

$rating-icon__active__color  : $theme-color-yellow1 !default;

$rating-label__hide          : false !default;
