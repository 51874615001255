.std {
    @extend .abs-margin-for-blocks-and-widgets;

    a {
        @include lib-link(
            $_link-color: $theme-color-pink,
            $_link-text-decoration: underline,
            $_link-color-visited: $theme-color-pink,
            $_link-text-decoration-visited: underline,
            $_link-color-hover: darken($theme-color-pink, 5%),
            $_link-text-decoration-hover: underline,
            $_link-color-active: darken($theme-color-pink, 5%),
            $_link-text-decoration-active: underline
        );
    }

    table {
        margin-bottom: $indent__m;

        tr {
            border-bottom: 1px solid $border-color__base;

            > td,
            > th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.sidebar {
    .footer-menu__top-link {
        @include lib-heading(h3);
        display: inline-block;
    }

    .footer-menu__sub-menu-topall {
        display: none;
    }

    .footer-menu__sub-menu-list {
        @include abs-sidebar-list();
        margin-bottom: 30px;
    }
}

.category-cms {
    a {
        img {
            width: 160px;
        }
    }
}

@include max-screen($screen__m) {
    .cms-vestigingen {
        .std > table > tbody > tr {
            display: flex;
            flex-wrap: wrap;
        }

        td {
            border: none;
            padding: 0 0 15px 0;
            margin-bottom: 30px;
        }

        iframe {
            width: 100%;
        }
    }
}
