.social-media {
    display: flex;
    flex-wrap: wrap;

    &__anchor {
        @include lib-icon-text-hide();
        @include lib-link(
            $_link-color                   : $color-white,
            $_link-color-visited           : $color-white,
            $_link-color-hover             : $color-white,
            $_link-text-decoration-hover   : none,
            $_link-color-active            : $color-white,
            $_link-text-decoration-active  : none
        );
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 21px
        );
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 50%;
        background-color: $brand__primary__color;

        &.facebook {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-facebook
            );
        }

        &.twitter {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-twitter
            );
        }

        &.instagram {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-instagram
            );
        }

        &.linkedin {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-linkedin
            );
        }

        &.pinterest {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-pinterest
            );
        }

        &.youtube {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-youtube
            );
        }
    }
}
