.top-search__container {
    position: relative;
}

.autocomplete-wrapper {
    @extend .abs-search-styles;
    margin-top: 10px;
    min-height: 3.5rem;
    box-sizing: border-box;
    text-align: left;

    z-index: 1000;
    position: absolute;
    width: 100%;
    display: none;

    &.active {
        display: block;
    }

    .autocomplete {
        .item-count {
            padding: 8px 10px;
            text-align: right;
            font-size: 13px;
            line-height: 13px;
            color: #999;
        }

        ul.item-wrapper {
            list-style: none;
            margin: 0;
            padding: 0;

            .autocomplete-item {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding: 5px 1rem;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                    text-decoration: none;
                }

                img {
                    width: 40px;
                    margin-right: 1rem;
                }

                .meta {
                    flex-grow: 1;
                    overflow: hidden;

                    .title {
                        display: block;
                        margin-bottom: 0;

                        span {
                            color: #333;
                            text-decoration: none;
                        }
                    }
                }

                .price {
                    .old-price {
                        text-decoration: line-through;
                        font-size: 11px;
                        color: #909090;
                        font-weight: 200;
                    }
                }
            }
        }

        .categories {
            padding-left: 10px;
        }

        .no-results {
            display: flex;
            justify-content: center;
            padding: 8px 10px;
            flex-direction: column;

            span {
                text-align: center;
                font-size: 1.2rem;
            }

            .suggestions {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-top: 10px;

                .suggestion-text {
                    font-weight: 700;
                    margin-right: 15px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    justify-content: center;

                    li {
                        margin-right: 15px;
                        display: block;
                        padding: 2px 12px;
                        border-radius: 20px;
                        border: 1px solid #d8dbe5;
                        font-weight: 600;

                        &:hover {
                            cursor: pointer;
                            border-color: #c9cddb;
                        }
                    }
                }
            }
        }
    }
}

.autocomplete-loader {
    position: absolute;
    top: 10px;
    right: 40px;
    width: 25px;
    display: none;

    &.active {
        display: block;
    }
}

.product-list-wrapper.loading {
    opacity: 0.5;
}

.filters {
    .selected-filters {
        h4 {
            border-bottom: 1px solid #d8dbe5;
            padding-bottom: 16px;
        }

        ol {
            list-style: none none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;

                .remove-button {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-remove,
                        $_icon-font-size: 10px,
                        $_icon-font-margin: 0 10px 0 0,
                    );

                    position: absolute;
                    width: 12px;
                    height: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        cursor: pointer
                    }
                }

                .attribute-title {
                    margin-left: 10px;
                    max-width: 80%;
                }
            }
        }
    }

    .filter-options-item {
        .filter-options-content {
            display: none;
            margin-bottom: 15px;

            .price-filter {
                display: flex;
                align-items: center;

                input {
                    min-width: 0;
                    font-size: 12px;
                    font-weight: 400;
                }

                .to {
                    padding: 0 5px;
                }

                button {
                    border: 0;
                    width: 50px;
                    min-width: 50px;
                    height: 32px;
                    margin-left: 10px;
                    @include lib-icon-font(
                        $_icon-font-content: $icon-next,
                        $_icon-font-size: 18px,
                        $_icon-font-margin: 0 10px 0 0,
                    );

                }
            }
        }

        &.active {
            .filter-options-content {
                display: block;
            }
        }
    }
}
