.navigation-horizontal {
    .filter-options {
        border-bottom: 1px solid #ccc;

        .filter-options-item {
            display: inline-table;
            border-bottom: 0;
        }
    }

    .filter-options-content {
        border: 1px solid #ccc;
        background-color: #fff;
        position: absolute !important;
        z-index: 10000;
    }
}
