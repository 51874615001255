.general-site-notice {
    .modal-header {
        display: none;
    }

    .modal-inner-wrap {
        max-width: 850px;

        .modal-content {
            padding: 0;
            margin: 0;

            .action-close {
                position: absolute;
                top: 10px;
                right: 10px;

                &::before {
                    font-size: 15px;
                }
            }
        }
    }
}

.general-site {
    &-popup-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-popup {
        display: none;

        &-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .btn--secondary {
                padding: 12px 21px;
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 250px;

            .inner {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                max-width: 80%;
            }

            .newsletter {
                margin-bottom: 30px;

                .subscribe {
                    flex-wrap: wrap;

                    .control {
                        min-width: 110px;
                        min-height: 47px;
                        margin-bottom: 20px;
                    }

                    .actions {
                        min-height: 47px;
                    }
                }

                .newsletter-text {
                    display: none;
                }

                .action {
                    &.primary {
                        border-radius: 0;
                    }
                }

                #newsletter-error.mage-error {
                    position: absolute;
                    margin-top: 50px;
                    color: red;
                }

            }
        }

        &-title {
            font-size: 28px;
            font-weight: 800;
            text-align: left;
            line-height: 1.2;
            color: #3e3f42;
            margin: 20px 0 30px 0;
        }

        &-description {
            font-size: 15px;
            text-align: left;
            line-height: 1.6;
            color: #3e3f42;
            margin-bottom: 30px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .general-site {
        &-popup {
            display: none;
            padding: 0;

            &-left {
                display: none;
            }

            &-right {
                width: 100%;
                padding: 0;
                max-width: 100%;
            }

        }

        &-inner-wrap {
            width: 340px;
        }
    }

    .modals-wrapper {
        .modal-popup {
            .modal-inner-wrap {
                width: 85%;
            }
        }
    }

    .modal-content {
        .action-close {
            top: 5px;
            right: 0;

            &::before {
                font-size: 15px;
                padding: 10px 15px 15px 10px;
            }
        }
    }
}
